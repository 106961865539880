import Head from 'next/head';
import Layout from '../components/layout/layout';
import { H2, P } from '../components/styleguide-remapped/styleguide-remapped';
import { NotFoundWrapper } from '../assets/css/style';

export default function Custom404() {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="language" content="de"></meta>
      </Head>
      <Layout key="404" pageType={'error-404'} hideSearch={false}>
        <NotFoundWrapper>
          <H2>404</H2>
          <P>Es tut uns leid. Die Seite wurde leider nicht gefunden.</P>
        </NotFoundWrapper>
      </Layout>
    </>
  );
}
